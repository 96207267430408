.pagination {
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.pagination > li > button,
.pagination > li > a
{
    background-color: white;
    color: #4f897d;
    font-size: 10pt;
    font-weight: normal;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > button:focus,
.pagination > li > button:hover,
.pagination > li > span:focus,
.pagination > li > span:hover
{
    color: #4f897d;
    background-color: #eee;
    border-color: #ddd;
}

.pagination > .active > button,
.pagination > .active > a
{
    background-color: #4f897d !Important;
    border: solid 1px #4f897d !Important;
}

.pagination > .active > button:hover,
.pagination > .active > a:hover
{
    background-color: #4f897d !Important;
    border: solid 1px #4f897d;
}
