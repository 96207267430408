div.search {
	position: relative;
	height: 30px;
	margin: auto;
	width: 300px;
}

.form-control:focus {
    border-color: rgba(80, 139, 119, 0.22);
    box-shadow: 0 0 0 0.2rem rgba(80, 139, 119, 0.22);
}

input#search {
  width: 300px;
  margin: auto;
}

input#search.empty {
 color: #b3b3b3;
 font-size: small;
 font-style: italic;
}

/******************
 * Suggestion Box *
 ******************/

#suggestions {
    position: absolute;
    left: 0px;
    top: 36px;
    width: 460px;

    background-color: white;
    border: 1px solid rgba(80, 139, 119, 0.22);

    overflow-x: hidden;
    padding: 0;

    z-index: 1000;
}

#slist {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

#slist li {
  color: #4f897d;
  height: 30px;
  padding-left: 5px;
  text-align: left;
  line-height: 30px;
  white-space: nowrap;
  font-weight: normal;
}

.selected {
  background-color: lightgray;
  background-color: rgba(80, 139, 119, 0.22);
}

.suggestionLink {
  text-decoration: none;
}
.suggestionLink:hover {
    text-decoration: none;
}

.suggestionLink:active {
    text-decoration: none;
}

/***********************
 * END: Suggestion Box *
 ***********************/
