.navbar {
    margin-bottom: 40px;
    background-color: #F4F4F4;
}
#logo {
    width: 40px;
    height: 30px;
}
.nav-link {
    font-weight: bold;
    white-space: nowrap !important;
}

.nav-link.active {
    color: grey !important;
}
.nav-link:hover {
    color: #4f897d !important;
}

svg.icon {
    margin-bottom: 4px;
}

.btn-outline-secondary {
    border-color: #4f897d !important;
    white-space: nowrap !important;
}

.btn-outline-secondary:hover {
    background-color: #4f897d !important;
}

.btn-outline-secondary:active {
    background-color: #4f897d !important;
}

.btn-primary {
    background-color: #4f897d !important;
    white-space: nowrap !important;
}

.btn-primary:hover {
    background-color: #3b8071 !important;
}

.btn-primary:active {
    background-color: #3b8071 !important;
}

.loginForm input[type=text], .loginForm input[type=password] {
  max-width: 710px;
  width: 100%;
  padding: 6px 20px;
  font-size: 75%;
  border: 1px solid #efefef !important;
  border-radius: 5px;
  background-color: #FFF;
}
