#tooltip-bottom {
    left: calc(100vw - 100%) !important;
}

#tooltip-bottom > .tooltip-inner {
  background-color: #fff;
  color: #4f897d;
  border: 1px solid #4f897d;
  max-width: 100%;
}

.bs-tooltip-bottom .arrow::before {
	border-bottom-color:#4f897d !important;
}
