.profile {
  margin: 10px 0px 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4f897d
}

.profile-prop {
  text-align: right;
  font-weight: bold;
  padding-right: 5px;
  vertical-align: top;
}

.profile-val {
  max-width: 600px;
}
