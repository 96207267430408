#about {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

}

#about img {
    /*height: 155px;*/
    margin-right: 20px;
    /*width: 200px;*/
}

#tdInfo {
    height: 155px;
    padding: 0px;
    vertical-align: top;
}

.aboutText {
    width: 400px;
}