.hierarchy-container {
  text-align: center;
  margin: 0 auto 20px auto;
}

.hierarchy-text {
  font-size: 12pt;
  font-weight: bold;
}

.hierarchy-text a {
  font-weight: bold;
  color: #4f897d;
}

.hierarchy-text a:link {
  text-decoration: none;
  color: #4f897d;
}

.hierarchy-text a:visited {
  text-decoration: none;
  color: #4f897d;
}

.hierarchy-text a:hover {
  text-decoration: underline;
  color: #4f897d;
}

.hierarchy-text a:active {
  text-decoration: underline;
  color: #4f897d;
}

.hierarchy-last-item a {
  color: red;
}

.hierarchy-last-item a:link {
  text-decoration: none;
  color: red;
}

.hierarchy-last-item a:visited {
  text-decoration: none;
  color: red;
}

.hierarchy-last-item a:hover {
  text-decoration: underline;
  color: red;
}

.hierarchy-last-item a:active {
  text-decoration: underline;
  color: red;
}
