.comment {
  display: block;
  width: 100%;
  border-collapse: collapse;
  margin: 0px;
  padding: 10px;
  overflow: auto;
  border: none;
}

.comment tr:hover {
  background-color: #D8D8D8;
}

.comment-image {
  text-align: center;
  vertical-align: middle;
  padding: 10px;
  width: 100px;
}

a.comment-body:link {
  text-decoration: inherit;
  color: inherit;
}
a.comment-body:visited {
  text-decoration: inherit;
  color: inherit;
}
a.comment-body:hover {
  text-decoration: inherit;
  color: inherit;
}

a img {
  outline : none;
}

img {
  border : 0;
}

img.comment-thumbnail {
  max-height: 100px;
  max-width: 100px;
  box-shadow: 5px 5px 6px #999;
}

.comment-body {
  width: 100%;
  vertical-align: top;
  padding: 10px;
}

div.comment-body {
  height: 100%;
}

.comment-header {
  font-size: 75%;
  color: #4f897d;
}

.comment-header-user {
  margin: 0px;
  padding: 0px;
  text-align: left;
}

.comment-header-date {
  float: right;
}

.comment-text {
  font-size: 80%;
  text-align: left;
}
