.collection {
  display: flex;
  justify-content: center;
  width: 100%;
  border-collapse: collapse;
  margin: 0px;
  padding: 0px;
  overflow: auto;
  border: none;
}

.collection-image {
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.collection-caption {
  margin: 10px 0px 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12pt;
  font-weight: normal;
  overflow: visible;
  white-space: nowrap;
}

.collection-caption a {
  font-weight: normal;
  color: #4f897d;
}

.collection-caption a:link {
  text-decoration: none;
  color: #4f897d;
}

.collection-caption a:visited {
  text-decoration: none;
  color: #4f897d;
}

.collection-caption a:hover {
  text-decoration: underline;
  color: #4f897d;
}

.collection-caption a:active {
  text-decoration: underline;
  color: #4f897d;
}
