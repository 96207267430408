html { margin-left: calc(100vw - 100%); }

body {
  margin: 0;
  font-family: Verdana,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-open {
  overflow:hidden;
  overflow-y:scroll;
  padding-right:0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a img {
  outline : none;
}

img {
  border : 0;
}

.component-title {
  font-size: 12pt;
  font-weight: bold;
  color: #4f897d;
  text-align: center;
  padding-bottom: 10px;
}

.component-title a {
  font-weight: bold;
  color: #4f897d;
}

.component-title a:link {
  text-decoration: none;
  color: #4f897d;
}

.component-title a:visited {
  text-decoration: none;
  color: #4f897d;
}

.component-title a:hover {
  text-decoration: underline;
  color: #4f897d;
}

.component-title a:active {
  text-decoration: underline;
  color: #4f897d;
}


.grey-background {
  background-color: #F4F4F4;
  margin: 0px 80px 50px 80px;
  padding: 10px 0px;
  border-radius: 10px;
}

.drop-shadow {
  box-shadow: 5px 5px 6px #999;
}

.unknown-image {
  border-style: solid;
  border-width: 10px;
  border-color: #F4F4F4;
}

.taxon {
  background-color: #FFFFFF;
  margin: 0px 80px 50px 80px;
  padding: 10px 0px;
  border-radius: 10px;
  overflow: auto;
}

.full-white {
  background-color: #FFFFFF;
  margin: 0px;
  padding: 10px 0px;
}
