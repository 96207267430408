html, body, #root, #root>div {
    height: 100%
  }

#wrap {
    margin-left: auto; /* IE: Needed to center info on the page. */
    margin-right: auto; /* IE: Needed to center info on the page. */
    min-height: 100%;
    width: 100%;
}

#content {
    overflow:auto;
    padding-bottom: 50px; /* must be same height as the footer */
    width: 100%;
}

#footer {
    clear: both;
    color: #4f897d;
    font-size: 12px;
    height: 30px;
    margin-top: -34px; /* negative value of footer height */
    position: relative;
    text-align: center;
}

.upload {
  position: fixed;
  top: 10%;
  left: 95%;
  z-index: 1;
  color: #4f897d;
  padding: 5px 10px 5px 10px;
  background-color: #F4F4F4;
}

.upload:hover {
  cursor: pointer;
}
