.image {
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
}

.image-container {
  position: relative;
}

.image-size-server {
  max-width: 600px;
  max-height: 600px
}

.arrow {
  position: fixed;
  top: 50%;
  z-index: 1;
}

.arrow:hover {
  cursor: pointer;
}

.ruler-image {
  position: absolute;
  left: 0;
  top: 0;
}

.ruler-image:hover {
  cursor: pointer;
}

.image-caption {
  font-size: 12pt;
  font-weight: normal;
  text-align: left;
  padding-top: 7px;
  color: #4f897d
}

.gender-icon {
  float: left;
  max-height: 20px;
}

.image-info {
  margin: 10px 0px 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4f897d
}

.image-info-prop {
  text-align: right;
  font-weight: bold;
  padding-right: 5px;
  vertical-align: top;
}

.image-info-val {
  max-width: 600px;
}

.image-edit-classification {
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4f897d;
}

.image-edit-classification-checkbox {
  border: 1px solid #4f897d;
  text-align: center;
  padding: 10px 20px;
  vertical-align: middle;
}

.image-edit-classification-prop {
  border: 1px solid #4f897d;
  text-align: right;
  font-weight: bold;
  padding: 10px 20px;
  vertical-align: middle;
}

.image-edit-classification-val {
  border: 1px solid #4f897d;
  padding: 10px 0px 10px 20px;
  max-width: 600px;
}

.image-edit-classification-val:hover {
    cursor: pointer;
    background: #efefef;
}

.image-edit-classification-val-noaction {
  border: 1px solid #4f897d;
  padding: 10px 20px;
  max-width: 600px;
}

.image-edit-watermark {
  display: block;
  text-align: center;
  vertical-align: middle;
  color: #4f897d;
  padding-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
}

.image-edit-info {
  margin: 0px;
  padding: 0px;
  color: #4f897d;
  max-width: 830px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.image-edit-info-prop {
  text-align: right;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: right;
  align-items: center;
}

.image-edit-info-val {
  padding: 0px 0px 0px 5px;
  max-width: 600px;
}

.ruler-button {
  border: none;
  border-radius: 6px;
  display: inline-block;
  font-size: 10pt;
  font-weight: bold;
  color: #000000;
  margin: 0px 10px;
  padding: 0px 10px;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
  cursor: pointer;
}

.ruler-button.active {
  background: #a9a9a9;
}

.ruler-button.inactive {
  background: #d3d3d3;
}

/* BEGIN: Comments */

#ksl-comments-list {
  list-style: none;
  margin-top: 30px;
  padding: 0px;
}

li.ksl-comment {
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 710px;
  width: 90%;
}

div.ksl-comment-caption {
  background-color: #efefef;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
p.ksl-comment-caption {
  color: #4f897d;
  font-size: 75%;
  padding: 2px 5px;
  margin-bottom: 0px;
}

span.ksl-comment-date {
  float: right;
  margin-right: 40px;
}

div.ksl-comment-text {

  border: 1px solid #efefef !important;
 /* border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;*/
}
p.ksl-comment-text {
  font-size: 80%;
  padding: 4px 6px;
  margin-bottom: 0px;
}

.comment-mod:hover {
  cursor: pointer;
}

.image-cell {
  position: relative;
}

.commentForm {
  margin-top: 30px;
  margin-bottom: 15px;
  display: block;
  text-align: center;
  max-width: 710px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.commentForm textarea, .commentForm input[type=text] {
  width: 100%;
  padding: 6px 20px;
  font-size: 75%;
  border: 1px solid #efefef !important;
  border-radius: 5px;
  background-color: #FFF;
}

.commentForm input[type=button], .commentForm input[type=submit], .commentForm input[type=reset] {
  text-decoration: none;
  cursor: pointer;
  font-size: 75%;
}

.btn-outline-secondary {
    border-color: #4f897d !important;
    white-space: nowrap !important;
}

.btn-outline-secondary:hover {
    background-color: #4f897d !important;
}

.btn-outline-secondary:active {
    background-color: #4f897d !important;
}

.btn-outline-secondary-selected {
    background-color: #4f897d !important;
}

.btn-primary {
    background-color: #4f897d !important;
    white-space: nowrap !important;
}

.btn-primary:hover {
    background-color: #3b8071 !important;
}

.btn-primary:active {
    background-color: #3b8071 !important;
}



/* END: Comments */
